import React, { useEffect, useState } from "react";
import Layout from "../components/Layout";
import styled from "styled-components";
import { Wrapper } from "../components/GlobalStyles";
import SectionOneBig from "../components/SectionOneBig";
import SectionTwo from "../components/SectionTwo";
import SectionThree from "../components/SectionThree";
import us from "../img/us.png";
import { Menu } from "../components/Menu/Menu";
import { Navbar } from "../components/Navbar";

const StyledWrapper = styled(Wrapper)`
  margin: 150px auto 150px;


  @media (max-width: 700px) {
    margin: 50px auto;
    padding: 0 30px;
  }
`

const Community = styled.div`
  width: 100%;

  @media (max-width: 1279px) {
    h2 {
      font-size: 50px;
    }
  }
`;

const CommunityList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
  margin-top: 100px;
  justify-items: center;
  align-items: center;

  img {
    width: 144px;
    height: 80px;
  }

  @media (max-width: 1279px) {
    grid-template-columns: 1fr;
    grid-row-gap: 20px;
  }
`;

const LabPage = ({ data }) => {
  const {
    markdownRemark: {
      frontmatter: {
        PL_us,
        ENG_us,
        PL_usText,
        ENG_usText,
        PL_spirit,
        ENG_spirit,
        PL_spiritText,
        ENG_spiritText,
        PL_progress,
        ENG_progress,
        PL_progressText,
        ENG_progressText,
        PL_community,
        ENG_community,
        galleryImages,
        progressImage,
        spiritImage,
        usImage,
      },
    },
  } = data || {};

  const texts = {
    PL_us,
    ENG_us,
    PL_usText,
    ENG_usText,
    PL_spirit,
    ENG_spirit,
    PL_spiritText,
    ENG_spiritText,
    PL_progress,
    ENG_progress,
    PL_progressText,
    ENG_progressText,
    PL_community,
    ENG_community,
  };

  const [language, setLanguage] = useState("pl");

  useEffect(() => {
    setLanguage(localStorage.getItem("language") || "pl");
  }, []);

  const translate = (key) => {
    return `${language?.toUpperCase()}_${key}`;
  };

  const handleLanguageClick = () => {
    if (language === "pl") {
      setLanguage("eng");
      localStorage.setItem("language", "eng");
    } else {
      setLanguage("pl");
      localStorage.setItem("language", "pl");
    }
  };

  return (
    <Layout>
      <>
        <Menu
          language={language === "pl" ? "eng" : "pl"}
          handleLanguageClick={handleLanguageClick}
        />
        <Navbar />
        <SectionOneBig
          title={texts[translate("us")]}
          content={texts[translate("usText")]}
          img={
            !!usImage?.childImageSharp
              ? usImage?.childImageSharp.fluid.src
              : usImage
          }
        />
        <SectionTwo
          title={texts[translate("spirit")]}
          content={texts[translate("spiritText")]}
          img={
            !!spiritImage?.childImageSharp
              ? spiritImage?.childImageSharp.fluid.src
              : spiritImage
          }
        />
        <SectionThree
          title={texts[translate("progress")]}
          content={texts[translate("progressText")]}
          img={
            !!progressImage?.childImageSharp
              ? progressImage?.childImageSharp.fluid.src
              : progressImage
          }
        />
        <StyledWrapper>
          <Community>
            <h2>{texts[translate("community")]}</h2>
            <CommunityList>
              {galleryImages
                ? galleryImages.map((item) => (
                    <img
                      alt={"logo"}
                      src={
                        !!item?.childImageSharp
                          ? item?.childImageSharp.fluid.src
                          : item
                      }
                    />
                  ))
                : ""}
            </CommunityList>
          </Community>
        </StyledWrapper>
      </>
    </Layout>
  );
};

export default LabPage;

export const labPageQuery = graphql`
  query LabPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        PL_us
        ENG_us
        PL_usText
        ENG_usText
        PL_spirit
        ENG_spirit
        PL_spiritText
        ENG_spiritText
        PL_progress
        ENG_progress
        PL_progressText
        ENG_progressText
        PL_community
        ENG_community
        galleryImages {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        usImage {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        spiritImage {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        progressImage {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
