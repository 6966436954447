import React from 'react'
import styled from "styled-components";

const StyledWrapper = styled.div`
  max-width: 1280px;
  margin: 200px auto;
  padding: 0 70px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 50px;

  @media (max-width: 700px) {
    grid-template-columns: 1fr;
    margin: 150px auto 100px;
    padding: 0 30px;
  }
`

const TextBox = styled.div`
  align-self: end;
  display: grid;
  p {
    margin-top: 20px;
  }
  
  h2 {
    align-self: start;
    font-size: 144px;
    line-height: 135px;
    margin-bottom: 40px;
  }
  
  @media (max-width: 700px) {
    h2 {
      font-size: 94px;
      line-height: 90px;
    }
  }
`;

const ImageBox = styled.div`
  max-width: 100%;
  width: auto;
  align-self: end;
  img {
    max-width: 100%;
    width: auto;
  }
`;

const SectionOneBig = ({title, content, img}) => {
    return (
        <StyledWrapper>
            <TextBox>
                <h2>{title}</h2>

                    <p>{content}</p>

            </TextBox>
            <ImageBox>
                <img
                    alt="team"
                    src={img}
                />
            </ImageBox>
        </StyledWrapper>
    )
}

export default SectionOneBig
