import React from "react";
import styled from "styled-components";
import ReactMarkdown from "react-markdown";
import style from "./markdown-styles.module.css";

const StyledWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 50px;
  max-width: 1280px;
  margin: 150px auto 0;
  padding: 0 70px;

  @media (max-width: 700px) {
    grid-template-columns: 1fr;
    grid-gap: 20px;
    margin: 100px auto 50px;
    padding: 0 30px;
  }
`;


const Image = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  
  img {
    height: 100vh;
    max-width: 100%;
    overflow: hidden;
    margin-right: -2px;
  }

  @media (max-width: 700px) {
    position: relative;
    right: 0;
    margin: 0 auto;
    width: auto;
    
    img {
      height: 100%;
    }
  }
`;

const TextBox = styled.div`
  height: 100vh;
  width: 100%;
  display: grid;

  @media (max-width: 700px) {
    height: auto;
  }
`;

const Text = styled.div`
  align-self: center;
  
  h3 {
    align-self: start;
    margin-bottom: 20px;
  }

  p {
    margin-top: 20px;
  }
`

const Container = styled.div`
  width: 100%;
  position: relative;
`

const SectionThree = ({ title, content, img }) => {
  return (
      <Container>
    <StyledWrapper>
        <TextBox>
          <Text>
          <h3>{title}</h3>
          <ReactMarkdown className={style.reactMarkDown} children={content} />
          </Text>
        </TextBox>
      <Image>
        <img alt="building" src={img} />
      </Image>
    </StyledWrapper>
      </Container>
  );
};

export default SectionThree;
